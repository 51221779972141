import React from 'react';
import { linkTo_UnCryptMailto } from '../../helper/unCryptMail';
import { email_formal_href_mailto_crypted, email_data_privacy_href_mailto_crypted } from '../../constants';

export const dataPrivacyResponsiblePerson = (
    <p className="contact-details">
        Kishor Rana<br />
        Im Haberacker 5b<br />
        76227 Karlsruhe<br />
        Tel: <a href="tel:+49 721 98614757">0721/98614757</a><br />
        E-Mail: <button className="link" onClick={() => { linkTo_UnCryptMailto(email_data_privacy_href_mailto_crypted); }}>kshrana.dev (at) gmail.com</button>
    </p>
);

export const imprintPersonTMG5 = (
    <p className="contact-details">
        Kishor Rana<br />
        Engineering<br />
        Im Haberacker 5b<br />
        76227 Karlsruhe<br />
        <br />
        Tel: <a href="tel:+49 721 98614757">0721/98614757</a><br />
        E-Mail: <button className="link" onClick={() => { linkTo_UnCryptMailto(email_formal_href_mailto_crypted); }}>kshrana.dev (at) gmail.com</button><br />
        <br />
        USt-Ident.-Nr.: DE328707377
    </p>
);
